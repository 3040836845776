

































































































































































































































import axios from "axios";
import { computed, defineComponent, reactive, ref, watch } from "@vue/composition-api";
import { debounce } from "lodash";
import { DialogProgrammatic as Dialog } from "buefy";
import Vue from "vue";
import { ToastProgrammatic as Toast } from "buefy";
import APP_CONFIG from "@/apps/core/modules/config";
import { isAdmin, isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import KaryaIlmiahVM, { KaryaIlmiah } from "../models/karyaIlmiah";
import KaryaIlmiahFM from "../models/karyaIlmiahForm";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { setRefreshRequest } from "../modules/store";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "KaryaIlmiahDetail",
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const form = new KaryaIlmiahFM(["hasil"], ["nilai", "tanggalMaju"]);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const instance = composition.instance;
    const karyaIlmiah = instance as KaryaIlmiah;
    const fileDataRef = ref(null);

    const canChange = computed(
      () => karyaIlmiah.extraData && karyaIlmiah.extraData.canChange
    );

    const isUploading = ref(false);
    const uploadPhoto = new UploadPhoto("karyailmiah", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const saveBerkas = async (url: string, isImage: boolean) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/karyailmiah/dokumen/`;
      const data = { "karya_ilmiah": paramsId, berkas: url, "is_image": isImage };
      try {
        const response = await axios.post(uploadDocUrl, data);
        if (karyaIlmiah.dokumenSet === null) {
          karyaIlmiah.dokumenSet = [];
        }
        const length = karyaIlmiah.dokumenSet.length;
        karyaIlmiah.dokumenSet.splice(length, 0, {
          id: response.data.id,
          berkas: response.data.berkas,
          isImage: response.data["is_image"],
        });
        fileDataRef.value = null;
        Toast.open("Berkas berhasil di upload.");
      } catch {
        Toast.open("Berkas gagal di upload.");
      }
    };

    const uploadFile = (berkas: File) => {
      uploadPhoto.requestAWS(berkas, saveBerkas);
    };

    const menuConfigs = [
      {
        label: "Ubah",
        onClick: () =>
          router.push({ name: "karyailmiah-change", params: { id: paramsId } }),
      },
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus karya ilmiah ini?",
            onConfirm: async () => {
              const karyaIlmiahVM = new KaryaIlmiahVM();
              if (karyaIlmiah.id === null) return;
              await karyaIlmiahVM.api.delete(karyaIlmiah.id);
              setRefreshRequest("context-menu");
              router.go(-1);
            },
          });
        },
      },
    ];

    const updateNilai = debounce((value) => {
      form.validate(value);
      if (form.getIsValid().value) form.save(SaveContext.Update, false);
    }, 1000);

    const updateTglMaju = debounce((value) => {
      form.validate(value);
      // const dataParams = { 'tanggal_maju': karyaIlmiah.tanggalMaju };
      if (form.getIsValid().value) form.save(SaveContext.Update, false);
    }, 1000);

    const galleryId = ref(`g${new Date().getTime()}`);
    const imageMap = reactive({} as Record<string, boolean>);

    const updateImageMap = (event: Event, imgId: string) => {
      const el = event.target as HTMLInputElement;
      imageMap[imgId] = el.checked;
    };
    const deleteImages = async () => {
      const karyaIlmiah = composition.instance as KaryaIlmiah;
      // api call untuk hapus gambar
      if (!karyaIlmiah.dokumenSet) return;
      const url = `${APP_CONFIG.baseAPIURL}/karyailmiah/dokumen-delete/`;
      try {
        const docs = karyaIlmiah.dokumenSet.filter((dok) => {
          return imageMap[dok.id];
        });
        const pkArr = docs.map((doc) => doc.id);
        const resp: Record<string, any> = await axios.delete(url, {
          data: { 'pk_list': pkArr },
        });
        const deletedDocs = resp.data;
        Toast.open("Data berhasil dihapus.");
        karyaIlmiah.dokumenSet = karyaIlmiah.dokumenSet.filter((dok) => {
          return !deletedDocs.includes(dok.id);
        });
        for (const docId of deletedDocs) {
          delete imageMap[docId];
        }
      } catch (error) {
        const errorMessage = "Gagal menghapus data.";
        Toast.open(errorMessage);
        throw new Error(errorMessage);
      }
      for (const docId of Object.keys(imageMap)) {
        imageMap[docId] = false;
      }
      galleryId.value = `g${new Date().getTime()}`;
    };

    watch(composition.instance, () => {
      const karyaIlmiah = instance as KaryaIlmiah;
      if (karyaIlmiah.dokumenSet) {
        for (const dok of karyaIlmiah.dokumenSet) {
          Vue.set(imageMap, dok.id, false);
        }
      }
    });

    function downloadFile(url: string) {
      window.open(url, "_blank");
    }

    function getFileIcon(url: string) {
      const re = /(?:\.([^.]+))?$/ as RegExp;
      const ext = re.exec(url);
      if (ext === null) return require('@/apps/core/assets/img/xlsx.png');
      return require(`@/apps/core/assets/img/${ext[1]}.png`);
    }

    return {
      // Data
      galleryId,
      isAdmin,
      isKonsulen,
      imageMap,
      isResiden,
      karyaIlmiah,
      menuConfigs,
      fileDataRef,
      isUploading,

      // Composition
      ...composition,

      // Computed
      canChange,

      // Method
      deleteImages,
      downloadFile,
      getFileIcon,
      uploadFile,
      toUserDateFormat,
      updateImageMap,
      updateNilai,
      updateTglMaju,
    };
  },
});

import FormModel from "@/apps/core/models/formModel";
import { KaryaIlmiah, KaryaIlmiahAPI } from "./karyaIlmiah";
import { setRefreshRequest } from "../modules/store";

export default class KaryaIlmiahFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new KaryaIlmiahAPI(),
      new KaryaIlmiah(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }
}

























































































































































import { debounce, has } from "lodash";
import { defineComponent, ref } from "@vue/composition-api";
import { GenericListAPI } from "@/apps/core/api/list";
import KaryaIlmiahFM from "../models/karyaIlmiahForm";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "KaryaIlmiahForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isCreate = !has(router.currentRoute.params, "id");
    const requiredFields = [
      "judul",
      "residen",
      "tanggal",
      "metode",
      "kegiatan",
      "pengujiSet",
      "pembimbingSet",
    ];
    const form = new KaryaIlmiahFM(requiredFields, ["tanggalMaju"]);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const karyaIlmiah = composition.instance;

    const pembimbingTags = ref([] as Array<Record<string, string>>);
    const pengujiTags = ref([] as Array<Record<string, string>>);

    const getFilteredPembimbingTags = async (nama: string) => {
      const url = `/anggota/konsulen/?fields_type=ac&q=${nama}`;
      const api = new GenericListAPI(url);
      const optionArr = await api.fetch();
      pembimbingTags.value = optionArr.reverse();
    };

    const getFilteredPengujiTags = async (nama: string) => {
      const url = `/anggota/konsulen/?fields_type=ac&q=${nama}`;
      const api = new GenericListAPI(url);
      const optionArr = await api.fetch();
      pengujiTags.value = optionArr.reverse();
    };

    const fetchPembimbingTags = debounce(getFilteredPembimbingTags, 500);
    const fetchPengujiTags = debounce(getFilteredPengujiTags, 500);

    return {
      // Data
      karyaIlmiah,
      isCreate,
      pembimbingTags,
      pengujiTags,

      // Composition
      ...composition,

      // Method
      fetchPembimbingTags,
      fetchPengujiTags,
    };
  },
});
